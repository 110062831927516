import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import "./DeepDRresult.css";
import { Button, notification, Space, Spin, Table } from "antd";

import * as echarts from "echarts";

import { getModelPriduct } from "../../stores/request";
import {
  DisKG_model_detailsById,
  drug_disease_detailsMapperSelectByDrugBank_id,
  drug_target_detailsMapperSelectByDrugBank_id,
  tarKG_model_result_detailsById,
  drug_detailsMapperSelectByDrugBank_id,
} from "../../constants/url";

function DeepDRresult(store) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    let currentData = [];
    let categoriesName = "";
    let currentLinks = [];
    let currentIndicator = [];
    let currentRadaData = [];

    if (store.store.dDRresult.RequestType === "default") {
      console.log("default");
      if (store.store.dDRresult.data) {
        let max = store.store.dDRresult.data[0].c5;
        let min =
          store.store.dDRresult.data[store.store.dDRresult.data.length - 1].c5;

        store.store.dDRresult.data.map((value, index, array) => {
          if (index < 20) {
            let size = ((value.c5 - min) / (max - min)) * 30;
            let indicatorData = (value.c5 - min) / (max - min);
            if (value.c5 === min) {
              size = ((value.Score - min + 0.001) / (max - min)) * 30;
              indicatorData = (value.c5 - min + 0.0001) / (max - min);
            }
            // console.log(value)
            // console.log(size);
            currentIndicator.push({ name: value["c4"], max: 1 });

            currentRadaData.push(indicatorData);

            currentData.push({
              name: value["c4"],
              value: 1,
              category: 0,
              symbolSize: size,
              drugID: value["c3"],
              label: value["c6"],
            });
            currentLinks.push({
              source: index,
              target: store.store.dDRresult.data.length,
            });
            categoriesName = value["c1"];
          }
        });
      }
      currentData.push({
        name: categoriesName,
        value: 0,
        category: 0,
        symbolSize: 10,
        itemStyle: {
          color: "red",
        },
      });
      let categories = [
        {
          name: categoriesName,
          keyword: {},
          base: categoriesName,
        },
      ];

      /*
      var chartDom = document.getElementById("main_1");
      var myChart = echarts.init(chartDom);
      var option;

      myChart.showLoading();
      myChart.showLoading();
      const webkitDep = {
        type: "force",
        categories: categories,
        nodes: currentData,
        links: currentLinks,
      };

      myChart.hideLoading();
      let legendCurrent = {
        data: [categoriesName],
      };
      option = {
        legend: legendCurrent,

        series: [
          {
            type: "graph",
            layout: "force",
            animation: false,
            label: {
              position: "right",
              formatter: "{b}",
            },
            draggable: true,
            data: webkitDep.nodes.map(function (node, idx) {
              // console.log(node);
              node.id = idx;
              return node;
            }),
            categories: webkitDep.categories,
            force: {
              edgeLength: 70,
              repulsion: 20,
              gravity: 0,
            },
            edges: webkitDep.links,
          },
        ],
      };
      myChart.setOption(option);

      option && myChart.setOption(option);*/

      var chartDom_2 = document.getElementById("main_2");
      var myChart_2 = echarts.init(chartDom_2);
      var option_2;

      option_2 = {
        legend: {
          data: [categoriesName],
        },
        radar: {
          // shape: 'circle',
          indicator: currentIndicator,
          // rich:{color:"black"}
        },
        series: [
          {
            name: categoriesName,
            type: "radar",
            data: [
              {
                value: currentRadaData,
                name: categoriesName,
              },
            ],
          },
        ],
      };

      myChart_2.on("click", async (param) => {
        if (
          param.event.topTarget["__dimIdx"] ||
          param.event.topTarget["__dimIdx"] == 0
        ) {
          console.log("Sub", param.event.topTarget);
          console.log(currentData[param.event.topTarget["__dimIdx"]]);
          setLoading(true);
          notification.open({
            message: "Collecting",
            description: "The method is running, please wait",
            duration: 8,
          });
          let res = await getDetailsFunction(
            currentData[param.event.topTarget["__dimIdx"]]["drugID"]
          );
          if (res.resultType) {
            store.store.servers.changeHomeStatue(7);
            store.store.detailsResult.request_success(res);
          }
          setLoading(false);
        }

        // console.log(myChart_2.radar.indicator)
        // console.log(myChart_2.radar.indicator[param.event.topTarget.__dimIdx])
      });

      // myChart_2.on('click', (param) => {
      //   console.log(param)
      // })
      option_2 && myChart_2.setOption(option_2);
    } else {
      if (store.store.dDRresult.data) {
        console.log(store.store.dDRresult.RequestType);

        let max = 19;
        let min = 0;

        store.store.dDRresult.data.map((value, index, array) => {
          if (index < 20) {
            let size = ((10 - index - min) / (max - min)) * 30;
            let indicatorData = (19 - index - min) / (max - min);
            if (19 - index === min) {
              size = ((19 - index - min + 0.001) / (max - min)) * 30;
              indicatorData = (19 - index - min + 0.0001) / (max - min);
            }

            console.log(size);
            currentIndicator.push({ name: value["c4"], max: 1 });

            currentRadaData.push(indicatorData);

            currentData.push({
              name: value["c4"],
              value: 1,
              category: 0,
              symbolSize: size,
              drugID: value["c3"],
              label: value["c6"],
            });
            currentLinks.push({
              source: index,
              target: store.store.dDRresult.data.length,
            });
            categoriesName = value["c1"];
          }
        });
      }
      currentData.push({
        name: categoriesName,
        value: 0,
        category: 0,
        symbolSize: 10,
        itemStyle: {
          color: "red",
        },
      });
      let categories = [
        {
          name: categoriesName,
          keyword: {},
          base: categoriesName,
        },
      ];
      // var chartDom = document.getElementById("main_1");
      // var myChart = echarts.init(chartDom);
      // var option;

      // myChart.showLoading();
      // myChart.showLoading();
      // const webkitDep = {
      //   type: "force",
      //   categories: categories,
      //   nodes: currentData,
      //   links: currentLinks,
      // };

      // myChart.hideLoading();
      // let legendCurrent = {
      //   data: [categoriesName],
      // };
      // option = {
      //   legend: legendCurrent,

      //   series: [
      //     {
      //       type: "graph",
      //       layout: "force",
      //       animation: false,
      //       label: {
      //         position: "right",
      //         formatter: "{b}",
      //       },
      //       draggable: true,
      //       data: webkitDep.nodes.map(function (node, idx) {
      //         // console.log(node);
      //         node.id = idx;
      //         return node;
      //       }),
      //       categories: webkitDep.categories,
      //       force: {
      //         edgeLength: 70,
      //         repulsion: 20,
      //         gravity: 0,
      //       },
      //       edges: webkitDep.links,
      //     },
      //   ],
      // };
      // myChart.setOption(option);

      // option && myChart.setOption(option);

      var chartDom_2 = document.getElementById("main_2");
      var myChart_2 = echarts.init(chartDom_2);
      var option_2;

      option_2 = {
        legend: {
          data: [categoriesName],
        },
        radar: {
          // shape: 'circle',
          indicator: currentIndicator,
        },

        series: [
          {
            name: categoriesName,
            type: "radar",
            data: [
              {
                value: currentRadaData,
                name: categoriesName,
              },
            ],
          },
        ],
      };

      option_2 && myChart_2.setOption(option_2);

      myChart_2.on("click", async (param) => {
        if (
          param.event.topTarget["__dimIdx"] ||
          param.event.topTarget["__dimIdx"] == 0
        ) {
          console.log("Sub", param.event.topTarget);
          console.log(currentData[param.event.topTarget["__dimIdx"]]);
          setLoading(true);
          notification.open({
            message: "Collecting",
            description: "The method is running, please wait",
            duration: 8,
          });
          let res = await getDetailsFunction(
            currentData[param.event.topTarget["__dimIdx"]]["drugID"]
          );
          if (res.resultType) {
            store.store.servers.changeHomeStatue(7);
            store.store.detailsResult.request_success(res);
          }
          setLoading(false);
        }
      });
    }
  }, []);

  const getDetailsFunction = async (key) => {
    let type = store.store.dDRresult.type;
    let result = { resultType: false, data: [] };
    if (type === "DeepDR" || type === "HetDR") {
      result = await getModelPriduct(
        drug_disease_detailsMapperSelectByDrugBank_id,
        key
      );
    } else if (type === "DeepDTnet") {
      result = await getModelPriduct(DisKG_model_detailsById, key);
    } else if (type === "KGMTL") {
      result = await getModelPriduct(
        drug_detailsMapperSelectByDrugBank_id,
        key
      );
    } else if (type === "CovKGE") {
      result = await getModelPriduct(tarKG_model_result_detailsById, key);
    } else {
      result = await getModelPriduct(
        drug_target_detailsMapperSelectByDrugBank_id,
        key
      );
    }
    return new Promise((res, rej) => {
      res(result);
    });
  };

  const columns = [
    // {
    //   title: "Disease ID",
    //   dataIndex: "c1",
    //   key: "c1",
    // },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      className: "columns-color",
    },
    {
      title: "Drug ID",
      dataIndex: "c3",
      key: "c3",
      className: "columns-color",
    },
    {
      title: "Drug Name",
      dataIndex: "c4",
      key: "c4",
      className: "columns-color",
    },
    {
      title: "Known Label",
      dataIndex: "c6",
      key: "c6",
      className: "columns-color"
    },
    {
      title: "Details",
      className: "columns-color",
      render: (record) => {
        return (
          <Button
            type="primary"
            className="columns-button"
            onClick={async () => {
              console.log(record.c3);
              setLoading(true);
              notification.open({
                message: "Collecting",
                description: "The method is running, please wait",
                duration: 8,
              });
              let res = await getDetailsFunction(record.c3);
              if (res.resultType) {
                store.store.servers.changeHomeStatue(7);
                store.store.detailsResult.request_success(res);
              }
              setLoading(false);
            }}
          >
            Details
          </Button>
        );
      },
    },
  ];

  const columns_DisKGE = [
    {
      title: "Disease ID",
      dataIndex: "c1",
      key: "c1",
    },
    {
      title: "Disease Name",
      dataIndex: "c2",
      key: "c2",
    },
    {
      title: "Drug ID",
      dataIndex: "c3",
      key: "c3",
    },
    {
      title: "Drug Name",
      dataIndex: "c4",
      key: "c4",
    },
    {
      title: "Known Label",
      dataIndex: "c5",
      key: "c5",
    },
    {
      title: "Details",
      render: (record) => {
        return (
          <Button
            onClick={async () => {
              // console.log(record.DrugID);
              setLoading(true);

              let res = await getDetailsFunction(record.DrugID);
              if (res.resultType) {
                store.store.servers.changeHomeStatue(7);
                store.store.detailsResult.request_success(res);
              }
              setLoading(false);
            }}
          >
            Details
          </Button>
        );
      },
    },
  ];

  return (
    <div className="result-body">
      {/* <div className="result-body-title">Result</div> */}
      {store.store.dDRresult.data.length > 0 ? (
        <div className="result-body-Id">
          {store.store.dDRresult.data[0]["c1"]} <Space />
          <Space />
          {store.store.dDRresult.data[0]["c2"]}
        </div>
      ) : (
        ""
      )}
      <Spin tip="Loading..." spinning={loading}>
        <div className="result-body-inline">
          <div className="left-float-main-main">
            <div className="metrics-table">
              <table>
                <tbody>
                  <tr>
                    <th rowSpan="2">Known Interactions</th>
                    <th>MRR</th>
                    <th>MRR@10</th>
                    <th>MRR@20</th>
                    <th>Hit@1</th>
                  </tr>
                  <tr>
                    <td>{store.store.metrics.data[3] || '-'}</td>
                    <td>{store.store.metrics.data[4] || '-'}</td>
                    <td>{store.store.metrics.data[5] || '-'}</td>
                    <td>{store.store.metrics.data[6] || '-'}</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">{store.store.metrics.data[2] || '-'}</td>
                    <th>Hit@3</th>
                    <th>Hit@5</th>
                    <th>Hit@10</th>
                    <th>Hit@20</th>
                  </tr>
                  <tr>
                    <td>{store.store.metrics.data[7] || '-'}</td>
                    <td>{store.store.metrics.data[8] || '-'}</td>
                    <td>{store.store.metrics.data[9] || '-'}</td>
                    <td>{store.store.metrics.data[10] || '-'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ height: "20px" }}></div>
            <div className="left-float-main-main-title">Radar Graph</div>
            <div className="left-float-main-main-radar">
              <div
                id="main_2"
                style={{ width: "90%", height: "380px", margin: "0 auto" }}
              ></div>
            </div>
            <div className="radar-tips">
              The Radar graph is based on the rank of drug or score, so it's a
              visualization of them. You can click the button of details to get
              information of drug
            </div>
          </div>

          <div className="left-float-main-table">
            {store.store.dDRresult.data.length > 0 ? (
              <div>
                <Table
                  columns={columns}
                  dataSource={store.store.dDRresult.data}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* <div className="result-chart">
        <div id="main_1" style={{ width: "50%", height: "400px" }}></div>
          </div> */}
      </Spin>
    </div>
  );
}

export default inject("store")(observer(DeepDRresult));
