// const ROOT = "http://10.220.56.57:8100/commonInter";
const ROOT = "https://drpredictor.com/api/commonInter";
// const ROOT = "http://121.41.170.194/api/commonInter";
// const ROOT = "http://drpredictor.com/commonInter";


export const aopedf_pdsSelectByProtein = `${ROOT}/aopedf_pdsSelectByProtein`;
export const aopedf_pds_SelectByKey = `${ROOT}/aopedf_pds_SelectByKey`;
export const deepDTnet_pds_SelectByProtein = `${ROOT}/deepDTnet_pds_SelectByProtein`;
export const deepDTnet_pds_SelectByKey = `${ROOT}/deepDTnet_pds_SelectByKey`;
export const hetDR_dds_SelectByKey = `${ROOT}/hetDR_dds_SelectByKey`;
export const hetDR_dds_SelectByDrug = `${ROOT}/hetDR_dds_SelectByDrug`;
export const deepDR_dds_SelectByDrug = `${ROOT}/deepDR_dds_SelectByDrug`;
export const deepDR_dds_SelectByKey = `${ROOT}/deepDR_dds_SelectByKey`;
export const drug_target_detailsMapperSelectByDrugBank_id = `${ROOT}/drug_target_detailsMapperSelectByDrugBank_id`;
export const drug_disease_detailsMapperSelectByDrugBank_id = `${ROOT}/drug_disease_detailsMapperSelectByDrugBank_id`;
export const disKG_model_result_sortsById = `${ROOT}/disKG_model_result_sortsById`;
export const disKG_model_result_sortsByName = `${ROOT}/disKG_model_result_sortsByName`;
export const DisKG_model_detailsById = `${ROOT}/DisKG_model_detailsById`;
export const tarKG_model_result_detailsWithBLOBsById = `${ROOT}/tarKG_model_result_detailsWithBLOBsById`;
export const tarKG_model_result_sortsByName = `${ROOT}/tarKG_model_result_sortsByName`;
export const tarKG_model_result_sortsById = `${ROOT}/tarKG_model_result_sortsById`;
export const tarKG_model_result_detailsById = `${ROOT}/tarKG_model_result_detailsById`;
export const DeepDTnet_AOPEDF_target_search_id = `${ROOT}/DeepDTnet_AOPEDF_target_search_id`;
export const TarKGE_target_search_id = `${ROOT}/TarKGE_target_search_id`;
export const DeepDR_HeTDR_disease_search_id = `${ROOT}/DeepDR_HeTDR_disease_search_id`;
export const DisKGE_disease_search_id = `${ROOT}/DisKGE_disease_search_id`;

export const DeepDTnet_AOPEDF_target_search_name = `${ROOT}/DeepDTnet_AOPEDF_target_search_name`;
export const TarKGE_target_search_name = `${ROOT}/TarKGE_target_search_name`;
export const DeepDR_HeTDR_disease_search_name = `${ROOT}/DeepDR_HeTDR_disease_search_name`;
export const DisKGE_disease_search_name = `${ROOT}/DisKGE_disease_search_name`;
export const KGMTL_target_search_name = `${ROOT}/KGMTL_model_result_sortsByName`;
export const KGMTL_target_search_id = `${ROOT}/KGMTL_model_result_sortsById`;
export const drug_detailsMapperSelectByDrugBank_id = `${ROOT}/drug_detailsMapperSelectByDrugBank_id`;
export const get_metrics_id = `${ROOT}/get_metrics_id`;
