import {
  Anchor,
  Button,
  Input,
  message,
  notification,
  Spin,
  List,
  Radio,
  Drawer,
  Typography,
  Tooltip,
} from "antd";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { list } from "../../../constants/list";
import {
  aopedf_pdsSelectByProtein,
  aopedf_pds_SelectByKey,
  deepDR_dds_SelectByDrug,
  deepDR_dds_SelectByKey,
  DeepDR_HeTDR_disease_search_id,
  DeepDR_HeTDR_disease_search_name,
  DeepDTnet_AOPEDF_target_search_id,
  DeepDTnet_AOPEDF_target_search_name,
  deepDTnet_pds_SelectByKey,
  deepDTnet_pds_SelectByProtein,
  DisKGE_disease_search_id,
  DisKGE_disease_search_name,
  disKG_model_result_sortsById,
  disKG_model_result_sortsByName,
  hetDR_dds_SelectByDrug,
  hetDR_dds_SelectByKey,
  KGMTL_target_search_id,
  KGMTL_target_search_name,
  TarKGE_target_search_id,
  TarKGE_target_search_name,
  tarKG_model_result_sortsById,
  tarKG_model_result_sortsByName,
  get_metrics_id
} from "../../../constants/url";
import { getModelPriduct } from "../../../stores/request";

import { Select } from "antd";
// import jsonp from 'fetch-jsonp';
import qs from "qs";

import "./serverPageModel.css";
import { Collapse } from "antd";
import axios from "axios";
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;
const { Link } = Anchor;

const { Option } = Select;

function ServerPageModel(store) {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  const [nameData, setNameData] = useState([]);
  const [idData, setIdData] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [searchValue_name, setSearchValue_name] = useState("");
  const [scoresTop, setScoresTop] = useState("");
  const [onSearching, setOnSearching] = useState(false);
  const [searchType, setSearchType] = useState(store.type);
  // const [tableValue, setTableValue] = useState([]);
  const [ABCDtype, setABCDtype] = useState("");

  const handleSearch_1 = (value) => {
    let url = "";

    if (searchType === "DeepDR" || searchType === "HetDR") {
      url = DeepDR_HeTDR_disease_search_name;
    } else if (searchType === "t_DeepDTnet" || searchType === "AOPEDF") {
      url = DeepDTnet_AOPEDF_target_search_name;
    } else if (searchType === "DeepDTnet") {
      url = DisKGE_disease_search_name;
    } else if (searchType === "CovKGE") {
      url = TarKGE_target_search_name;
    } else {
      url = KGMTL_target_search_name;
    }
    console.log(url);
    if (url !== KGMTL_target_search_name) {
      try {
        if (value) {
          fetch(url, value, (data) => setNameData(data));
        } else {
          setNameData([]);
        }
      } catch (e) { }
    }
  };

  const handleSearch_2 = (value) => {
    let url = "";
    if (searchType === "DeepDR" || searchType === "HetDR") {
      url = DeepDR_HeTDR_disease_search_id;
    } else if (searchType === "t_DeepDTnet" || searchType === "AOPEDF") {
      url = DeepDTnet_AOPEDF_target_search_id;
    } else if (searchType === "DeepDTnet") {
      url = DisKGE_disease_search_id;
    } else if (searchType === "CovKGE") {
      url = TarKGE_target_search_id;
    } else {
      url = KGMTL_target_search_id;
    }
    console.log(url);
    if (url !== KGMTL_target_search_id) {
      try {
        if (value) {
          fetch(url, value, (data) => setIdData(data));
        } else {
          setIdData([]);
        }
      } catch (e) { }
    }
  };

  const options_1 = nameData.map((d) => (
    <Option key={d.value}>{d.text}</Option>
  ));
  const options_2 = idData.map((d) => <Option key={d.value}>{d.text}</Option>);

  const handleChange_1 = (value) => {
    setSearchValue_name(value);
  };
  const handleChange_2 = (value) => {
    setSearchValue(value);
  };

  let timeout;
  let currentValue;

  const fetch = (url, value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;

    function fake() {
      const str = qs.stringify({
        code: "utf-8",
        q: value,
      });
      axios
        .post(url, { key: value })
        .then((response) => response.data)
        .then((res) => {
          if (res.status === 200 && res.data !== "0") {
            console.log(res.data);
            if (currentValue === value) {
              const result = res.data;
              const data = [];
              result.forEach((r) => {
                data.push({
                  value: r,
                  text: r,
                });
              });
              callback(data);
            }
          }
        });
    }

    timeout = setTimeout(fake, 300);
  };

  const onChange_searchValue = ({ target: { value } }) => {
    setSearchValue(value);

    console.log(value);
  };
  const onChange_searchValue_name = ({ target: { value } }) => {
    setSearchValue_name(value);
    console.log(value);
  };

  const onChange_scoresTop = ({ target: { value } }) => {
    let justy = Boolean(parseInt(value));

    if (justy || value === "") {
      if (
        searchType === "DeepDTnet" ||
        searchType === "CovKGE" ||
        searchType === "KGMTL"
      ) {
        if (value > 20) {
          message.info("please input number greater than 6 and less than 20");
        } else {
          setScoresTop(value);
        }
      } else {
        setScoresTop(value);
      }
    } else {
      message.info("please input number");
      return;
    }
  };
  const handleClick = (e, link) => {
    e.preventDefault();
    console.log(link);
  };

  const [radioValue, setRadioValue] = useState(store.type);
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const typeToMetric = {
    DeepDR: "DeepDR",
    HetDR: "HeTDR",
    DeepDTnet: "DisKG",
    t_DeepDTnet: "deepDTnet",
    AOPEDF: "AOPEDF",
    CovKGE: "TarKG",
    KGMTL: "KG"
  };
  const searchFunction = async (type) => {
    let tempStar = 0;
    if (searchValue !== "" && searchValue_name !== "") {
      tempStar = 1;
    } else if (searchValue !== "" && searchValue_name === "") {
      tempStar = 3;
    } else if (searchValue_name !== "" && searchValue === "") {
      tempStar = 2;
    }
    if (tempStar !== 0) {
      let dataCurrent = [];
      let res = { resultType: false, data: [], type: type };
      switch (type) {
        case "DeepDR": {
          if (tempStar === 1 || tempStar === 3) {
            res = await getModelPriduct(
              deepDR_dds_SelectByKey,
              searchValue,
              "default",
              "DeepDR"
            );
          } else {
            res = await getModelPriduct(
              deepDR_dds_SelectByDrug,
              searchValue_name,
              "default",
              "DeepDR"
            );
          }
          break;
        }
        case "HetDR": {
          if (tempStar === 1 || tempStar === 3) {
            res = await getModelPriduct(
              hetDR_dds_SelectByKey,
              searchValue,
              "default",
              "HetDR"
            );
          } else {
            res = await getModelPriduct(
              hetDR_dds_SelectByDrug,
              searchValue_name,
              "default",
              "HetDR"
            );
          }
          break;
        }

        case "DeepDTnet": {
          if (tempStar === 1 || tempStar === 3) {
            res = await getModelPriduct(
              disKG_model_result_sortsById,
              searchValue,
              "DeepDTnet",
              "DeepDTnet"
            );
          } else {
            res = await getModelPriduct(
              disKG_model_result_sortsByName,
              searchValue_name,
              "DeepDTnet",
              "DeepDTnet"
            );
          }
          break;
        }
        case "t_DeepDTnet":
          if (tempStar === 1 || tempStar === 3) {
            res = await getModelPriduct(
              deepDTnet_pds_SelectByKey,
              searchValue,
              "default",
              "t_DeepDTnet"
            );
          } else {
            res = await getModelPriduct(
              deepDTnet_pds_SelectByProtein,
              searchValue_name,
              "default",
              "t_DeepDTnet"
            );
          }
          break;
        case "CovKGE":
          if (tempStar === 1 || tempStar === 3) {
            res = await getModelPriduct(
              tarKG_model_result_sortsById,
              searchValue,
              "CovKGE",
              "CovKGE"
            );
          } else {
            res = await getModelPriduct(
              tarKG_model_result_sortsByName,
              searchValue_name,
              "CovKGE",
              "CovKGE"
            );
          }
          break;
        case "KGMTL":
          if (tempStar === 1 || tempStar === 3) {
            res = await getModelPriduct(
              KGMTL_target_search_id,
              searchValue,
              "KGMTL",
              "KGMTL"
            );
          } else {
            res = await getModelPriduct(
              KGMTL_target_search_name,
              searchValue_name,
              "KGMTL",
              "KGMTL"
            );
          }
          break;

        case "AOPEDF": {
          if (tempStar === 1 || tempStar === 3) {
            res = await getModelPriduct(
              aopedf_pds_SelectByKey,
              searchValue,
              "default",
              "AOPEDF"
            );
          } else {
            res = await getModelPriduct(
              aopedf_pdsSelectByProtein,
              searchValue_name,
              "default",
              "AOPEDF"
            );
          }
          break;
        }
        default:
          break;
      }
      if (res.resultType) {
        console.log(res);
        res.data.map((value, index, array) => {
          if (index < scoresTop) {
            let current_data = value;
            current_data.rank = index + 1;
            dataCurrent.push(current_data);
          }
        });
      } else {
        console.log("oh no!");
      }
      return new Promise((reso, rej) => {
        if (dataCurrent.length > 0) {
          reso({
            data: dataCurrent,
            type: type,
            RequestType: res.RequestType,
            DataType: res.DataType,
          });
        } else {
          reso(false);
        }
      });
    } else {
      return new Promise((reso, rej) => {
        reso(false);
      });
    }
  };
  const radioChange = (e) => {
    console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
    // store.type=e.target.value
    setSearchType(e.target.value);
  };
  let ABC = "";
  let ABC_forward = "";
  const description_list = {
    DeepDR:
      "This model is a network-based deep learning method for drug repositioning that integrates 10 drug-related networks, consisting of one each for drug-disease, drug-side-effect, and drug-target, as well as seven types of drug-drug similarities.",
    HetDR:
      "This model is a drug repositioning method based on heterogeneous networks and text mining, which combines drugs features from multiple networks and disease features from biomedical corpora to predict the correlation scores between drugs and diseases.",
    DeepDTnet:
      "The model is a variant of CovKGE, which uses a comprehensive biological knowledge graph called DRKG relating genes, compounds, diseases, biological processes, side effects, and symptoms for predicting candidate drugs with potential therapeutic effects for a certain disease.",
    t_DeepDTnet:
      "The model is a network-based deep learning method for new target identification and drug repositioning in a heterogeneous drug—gene—disease network embedding 15 types of chemical, genomic, phenotypic, and cellular network profiles.",
    AOPEDF:
      "The model is a network-based computational framework, and an arbitrary-order proximity embedded deep forest approach for the prediction of drug-target interactions.",
    CovKGE:
      "The model is also a variant of CovKGE, which uses a comprehensive biological knowledge graph called DRKG relating genes, compounds, diseases, biological processes, side effects, and symptoms for predicting candidate drugs with the potential to target a specific target.",
    KGMTL:
      "The model is a large-scale knowledge graph enhanced multi-task Learning model, which extracts the features from both knowledge graph and molecular graph in a synergistic way for molecular interaction prediction tasks."
  };
  return (
    <div className="serverPageModel-body">
      <Drawer
        title={
          searchType === "DeepDTnet" ||
            searchType === "HetDR" ||
            searchType === "DeepDR"
            ? "Disease List"
            : "Target List"
        }
        placement="right"
        width={500}
        onClose={onClose}
        visible={visible}
      >
        <div id="Typography-serverPageModel">
          <Typography>
            <div>
              <Anchor
                className="Typography-serverPageModel-Anchor"
                getContainer={() =>
                  document.getElementById("Typography-serverPageModel")
                }
                onClick={handleClick}
              >
                <Link href="#A" title="A" />
                <Link href="#B" title="B" />
                <Link href="#C" title="C" />
                <Link href="#D" title="D" />
                <Link href="#E" title="E" />
                <Link href="#F" title="F" />
                <Link href="#G" title="G" />
                <Link href="#H" title="H" />
                <Link href="#I" title="I" />
                <Link href="#J" title="J" />
                <Link href="#K" title="K" />
                <Link href="#L" title="L" />
                <Link href="#M" title="M" />
                <Link href="#N" title="N" />
                <Link href="#O" title="O" />
                <Link href="#P" title="P" />
                <Link href="#Q" title="Q" />
                <Link href="#R" title="R" />
                <Link href="#S" title="S" />
                <Link href="#T" title="T" />
                <Link href="#U" title="U" />
                <Link href="#V" title="V" />
                <Link href="#W" title="W" />
                <Link href="#X" title="X" />
                <Link href="#Y" title="Y" />
                <Link href="#Z" title="Z" />
              </Anchor>
            </div>

            {searchType === "DeepDTnet"
              ? list.DisKGE_disease.map((value, index, array) => {
                let current_C = "";
                if (ABC !== value.name.toUpperCase()[0]) {
                  current_C = value.name[0].toUpperCase();
                  ABC = value.name[0].toUpperCase();
                }

                if (ABC !== ABC_forward) {
                  ABC_forward = ABC;
                  return (
                    <>
                      <Title level={2} id={ABC}>
                        {current_C}
                      </Title>
                      <blockquote
                        onClick={() => {
                          setSearchValue(value.id);
                          setSearchValue_name(value.name);
                          message.success("Inputting done!");
                          onClose();
                        }}
                      >
                        <Title level={4}>{value.name}</Title>
                        <Paragraph>{value.id}</Paragraph>
                      </blockquote>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Title level={2}>{current_C}</Title>
                      <blockquote
                        onClick={() => {
                          setSearchValue(value.id);
                          setSearchValue_name(value.name);
                          message.success("Inputting done!");
                          onClose();
                        }}
                      >
                        <Title level={4}>{value.name}</Title>
                        <Paragraph>{value.id}</Paragraph>
                      </blockquote>
                    </>
                  );
                }
              })
              : ""}

            {searchType === "DeepDR" || searchType === "HetDR"
              ? list.DeepDR_HeTDR_disease.map((value, index, array) => {
                let current_C = "";
                if (ABC !== value.name.toUpperCase()[0]) {
                  current_C = value.name[0].toUpperCase();
                  ABC = value.name[0].toUpperCase();
                }

                if (ABC !== ABC_forward) {
                  ABC_forward = ABC;
                  return (
                    <>
                      <Title level={2} id={ABC}>
                        {current_C}
                      </Title>
                      <blockquote
                        onClick={() => {
                          setSearchValue(value.id);
                          setSearchValue_name(value.name);
                          message.success("Inputting done!");
                          onClose();
                        }}
                      >
                        <Title level={4}>{value.name}</Title>
                        <Paragraph>{value.id}</Paragraph>
                      </blockquote>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Title level={2}>{current_C}</Title>
                      <blockquote
                        onClick={() => {
                          setSearchValue(value.id);
                          setSearchValue_name(value.name);
                          message.success("Inputting done!");
                          onClose();
                        }}
                      >
                        <Title level={4}>{value.name}</Title>
                        <Paragraph>{value.id}</Paragraph>
                      </blockquote>
                    </>
                  );
                }
              })
              : ""}

            {searchType === "t_DeepDTnet" || searchType === "AOPEDF"
              ? list.DeepDTnet_AOPEDF_target.map((value, index, array) => {
                let current_C = "";
                if (ABC !== value.name.toUpperCase()[0]) {
                  current_C = value.name[0].toUpperCase();
                  ABC = value.name[0].toUpperCase();
                }

                if (ABC !== ABC_forward) {
                  ABC_forward = ABC;
                  return (
                    <>
                      <Title level={2} id={ABC}>
                        {current_C}
                      </Title>
                      <blockquote
                        onClick={() => {
                          setSearchValue(value.id);
                          setSearchValue_name(value.name);
                          message.success("Inputting done!");
                          onClose();
                        }}
                      >
                        <Title level={4}>{value.name}</Title>
                        <Paragraph>{value.id}</Paragraph>
                      </blockquote>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Title level={2}>{current_C}</Title>
                      <blockquote
                        onClick={() => {
                          setSearchValue(value.id);
                          setSearchValue_name(value.name);
                          message.success("Inputting done!");
                          onClose();
                        }}
                      >
                        <Title level={4}>{value.name}</Title>
                        <Paragraph>{value.id}</Paragraph>
                      </blockquote>
                    </>
                  );
                }
              })
              : ""}

            {searchType === "CovKGE"
              ? list.TarKGE_target.map((value, index, array) => {
                let current_C = "";
                if (ABC !== value.name.toUpperCase()[0]) {
                  current_C = value.name[0].toUpperCase();
                  ABC = value.name[0].toUpperCase();
                }

                if (ABC !== ABC_forward) {
                  ABC_forward = ABC;
                  return (
                    <>
                      <Title level={2} id={ABC}>
                        {current_C}
                      </Title>
                      <blockquote
                        onClick={() => {
                          setSearchValue(value.id);
                          setSearchValue_name(value.name);
                          message.success("Inputting done!");
                          onClose();
                        }}
                      >
                        <Title level={4}>{value.name}</Title>
                        <Paragraph>{value.id}</Paragraph>
                      </blockquote>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Title level={2}>{current_C}</Title>
                      <blockquote
                        onClick={() => {
                          setSearchValue(value.id);
                          setSearchValue_name(value.name);
                          message.success("Inputting done!");
                          onClose();
                        }}
                      >
                        <Title level={4}>{value.name}</Title>
                        <Paragraph>{value.id}</Paragraph>
                      </blockquote>
                    </>
                  );
                }
              })
              : ""}

            {searchType === "KGMTL"
              ? list.KGMTL_target.map((value, index, array) => {
                let current_C = "";
                if (ABC !== value.name.toUpperCase()[0]) {
                  current_C = value.name[0].toUpperCase();
                  ABC = value.name[0].toUpperCase();
                }

                if (ABC !== ABC_forward) {
                  ABC_forward = ABC;
                  return (
                    <>
                      <Title level={2} id={ABC}>
                        {current_C}
                      </Title>
                      <blockquote
                        onClick={() => {
                          setSearchValue(value.id);
                          setSearchValue_name(value.name);
                          message.success("Inputting done!");
                          onClose();
                        }}
                      >
                        <Title level={4}>{value.name}</Title>
                        <Paragraph>{value.id}</Paragraph>
                      </blockquote>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Title level={2}>{current_C}</Title>
                      <blockquote
                        onClick={() => {
                          setSearchValue(value.id);
                          setSearchValue_name(value.name);
                          message.success("Inputting done!");
                          onClose();
                        }}
                      >
                        <Title level={4}>{value.name}</Title>
                        <Paragraph>{value.id}</Paragraph>
                      </blockquote>
                    </>
                  );
                }
              })
              : ""}
          </Typography>
        </div>
      </Drawer>
      <div className="serverPageModel-body-inside">
        <Spin tip="Loading..." spinning={onSearching}>
          <List
            header={
              <>
                <div className="serverPageModel-title">
                  {store.type === searchType
                    ? store.type !== "t_DeepDTnet"
                      ? store.type !== "DeepDTnet"
                        ? store.type !== "CovKGE"
                          ? store.type
                          : "TarKGE"
                        : "DisKGE"
                      : "DeepDTnet"
                    : searchType !== "t_DeepDTnet"
                      ? searchType !== "DeepDTnet"
                        ? searchType !== "CovKGE"
                          ? searchType
                          : "TarKGE"
                        : "DisKGE"
                      : "DeepDTnet"}
                </div>
                <div className="serverPageModel-description">
                  {description_list[searchType]}
                </div>
              </>
            }
            footer={
              <div className="serverPageModel-inputValue-footer">
                <div className="button-serverPageModel-last">
                  <Button
                    type="primary"
                    onClick={async () => {
                      if (scoresTop >= 6) {
                        console.log("searching");
                        setOnSearching(true);
                        notification.open({
                          message: "Predicting",
                          description: "The model is running, please wait",
                          duration: 2,
                        });
                        setTimeout(async () => {
                          let result = await searchFunction(searchType);
                          setOnSearching(false);
                          if (result) {
                            // setTableValue(result);
                            let res = {
                              data: result,
                              type: searchType,
                            };
                            store.store.dDRresult.request_success(res);

                            // Get metrics
                            const metricsRes = await getModelPriduct(
                              get_metrics_id,
                              searchValue + '_' + typeToMetric[searchType]
                            );
                            if (metricsRes.resultType) {
                              store.store.metrics.request_success(metricsRes.data);
                            }
                            store.store.servers.changeHomeStatue(11);
                          } else {
                            message.info(`There is no ${store.type === "DeepDR" ||
                              store.type === "HetDR" ||
                              store.type === "DeepDTnet"
                              ? "Disease "
                              : "Target"} match this id`);
                          }
                        }, 3000);
                      } else {
                        message.info(
                          "please input number greater than 6 and less than 20"
                        );
                      }
                    }}
                  >
                    Predict
                  </Button>

                  <Button
                    onClick={() => {
                      setScoresTop("");
                      setSearchValue("");
                      setSearchValue_name("");
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            }
            bordered
            dataSource={[
              <Collapse
                defaultActiveKey={["0"]}
                className="panel-serverPageModel"
              >
                <Panel header="Model Options" key="0">
                  {store.type === "DeepDR" ||
                    store.type === "HetDR" ||
                    store.type === "DeepDTnet" ? (
                    <Radio.Group onChange={radioChange} value={radioValue}>
                      <Radio value={"DeepDR"}>DeepDR</Radio>
                      <Radio value={"HetDR"}>HetDR</Radio>
                      <Radio value={"DeepDTnet"}>DisKGE</Radio>
                    </Radio.Group>
                  ) : (
                    <Radio.Group onChange={radioChange} value={radioValue}>
                      <Radio value={"t_DeepDTnet"}>DeepDTnet</Radio>
                      <Radio value={"AOPEDF"}>AOPEDF</Radio>
                      <Radio value={"CovKGE"}>TarKGE</Radio>
                      <Radio value={"KGMTL"}>KG-MTL</Radio>
                    </Radio.Group>
                  )}
                </Panel>
              </Collapse>,
              <Collapse
                defaultActiveKey={["1", "2", "3"]}
                className="panel-serverPageModel"
              >
                <Panel
                  header={
                    store.type === "DeepDR" ||
                      store.type === "HetDR" ||
                      store.type === "DeepDTnet"
                      ? "Disease ID "
                      : "Target Id "
                  }
                  key="1"
                >
                  <div className="serverPageModel-inputValue">
                    {/* <div>
                        {store.type === "DeepDR" ||
                        store.type === "HetDR" ||
                        store.type === "DeepDTnet"
                          ? "Disease ID"
                          : "Target Id"}
                        :
                      </div> */}
                    Input:
                    <Select
                      // mode="tags"
                      showSearch
                      value={searchValue}
                      // searchValue={searchValue}
                      style={{
                        width: "50%",
                        margin: "0px 50px 0 0",
                        textAlign: "right",
                      }}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      // autoFocus={true}
                      onSearch={handleSearch_2}
                      onChange={handleChange_2}
                      notFoundContent={null}
                    >
                      {options_2}
                    </Select>
                    {/* <Input
                      value={searchValue}
                      onChange={onChange_searchValue}
                    /> */}
                    <Tooltip title="Quickly get the specific disease or target ID\Name and click it to input">
                      <Button type="defaullt" onClick={showDrawer}>
                        {store.type === "DeepDR" ||
                          store.type === "HetDR" ||
                          store.type === "DeepDTnet"
                          ? "Disease "
                          : "Target "}
                        List
                      </Button>
                    </Tooltip>
                    <Button
                      onClick={async () => {
                        switch (searchType) {
                          case "DeepDR": {
                            setSearchValue("C0342731");
                            setSearchValue_name(
                              "Deficiency of mevalonate kinase"
                            );
                            break;
                          }
                          case "HetDR": {
                            setSearchValue("C0342731");
                            setSearchValue_name(
                              "Deficiency of mevalonate kinase"
                            );
                            break;
                          }

                          case "DeepDTnet": {
                            setSearchValue("Disease::MESH:D000038");
                            setSearchValue_name("Abscess");
                            break;
                          }
                          case "t_DeepDTnet":
                            setSearchValue("9874");
                            setSearchValue_name("TLK1");
                            break;
                          case "CovKGE":
                            setSearchValue("2157");
                            setSearchValue_name("HPRD");
                            break;
                          case "KGMTL":
                            setSearchValue("2157");
                            setSearchValue_name("HPRD");
                          case "AOPEDF": {
                            setSearchValue("9874");
                            setSearchValue_name("TLK1");
                            break;
                          }
                          default:
                            break;
                        }

                        setScoresTop(20);
                      }}
                    >
                      Example
                    </Button>
                  </div>

                  <div className="input-select-model"></div>
                  <div className="input-tips">
                    (Users can quickly get the specific {
                      store.type === "DeepDR" ||
                        store.type === "HetDR" ||
                        store.type === "DeepDTnet" ?
                        "disease ID\\Name" :
                        "target"
                    } through the Search List.)
                  </div>
                </Panel>
                <Panel
                  header={
                    store.type === "DeepDR" ||
                      store.type === "HetDR" ||
                      store.type === "DeepDTnet"
                      ? "Disease Name"
                      : "Target Name"
                  }
                  key="2"
                >
                  <div className="serverPageModel-inputValue">
                    {/* <div>
                              {store.type === "DeepDR" ||
                              store.type === "HetDR" ||
                              store.type === "DeepDTnet"
                                ? "Disease Name"
                                : "Target Name"}
                              :
                              </div>{" "} */}
                    Input:
                    <Select
                      showSearch
                      value={searchValue_name}
                      style={{
                        width: "50%",
                        margin: "0px 50px 0 0",
                        textAlign: "right",
                      }}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={handleSearch_1}
                      onChange={handleChange_1}
                      notFoundContent={null}
                      textAlign="right"
                    >
                      {options_1}
                    </Select>
                    {/* <Input
                      value={searchValue_name}
                      onChange={onChange_searchValue_name}
                    /> */}
                    <Tooltip title="Quickly get the specific disease or target ID\Name and click it to input">
                      <Button type="defaullt" onClick={showDrawer}>
                        {store.type === "DeepDR" ||
                          store.type === "HetDR" ||
                          store.type === "DeepDTnet"
                          ? "Disease "
                          : "Target "}
                        List
                      </Button>
                    </Tooltip>
                    <Button
                      onClick={async () => {
                        switch (searchType) {
                          case "DeepDR": {
                            setSearchValue("C0342731");
                            setSearchValue_name(
                              "Deficiency of mevalonate kinase"
                            );
                            break;
                          }
                          case "HetDR": {
                            setSearchValue("C0342731");
                            setSearchValue_name(
                              "Deficiency of mevalonate kinase"
                            );
                            break;
                          }

                          case "DeepDTnet": {
                            setSearchValue("C0042109");
                            setSearchValue_name("Urticaria");
                            break;
                          }
                          case "t_DeepDTnet":
                            setSearchValue("9874");
                            setSearchValue_name("TLK1");
                            break;
                          case "CovKGE":
                            setSearchValue("2157");
                            setSearchValue_name("HPRD");
                            break;
                          case "KGMTL":
                            setSearchValue("5027");
                            setSearchValue_name("P2RX7");
                            break;

                          case "AOPEDF": {
                            setSearchValue("9874");
                            setSearchValue_name("TLK1");
                            break;
                          }
                          default:
                            break;
                        }

                        setScoresTop(20);
                      }}
                    >
                      Example
                    </Button>
                  </div>
                </Panel>
                <Panel header="Drugs Top:" key="3">
                  <div className="serverPageModel-inputValue">
                    {/* <div>Drugs Top:</div> */}
                    Input:
                    <Input
                      value={scoresTop}
                      onChange={onChange_scoresTop}
                    // style={{ width: "80px" }}
                    />{" "}
                  </div>
                </Panel>
              </Collapse>,
            ]}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Spin>
      </div>

      <div className="tips-serverPageModel">
        tips:
        <ol>
          {searchType === "DeepDR" && (
            <li>
              Disease ID/Name: DeepDR supports the inputed Disease name or
              theunique ID of disease.
            </li>
          )}
          {searchType === "DeepDTnet" && (
            <li>
              Disease ID/Name: DisKGE supports the inputed Disease name or
              theunique ID of disease.
            </li>
          )}
          {searchType === "HetDR" && (
            <li>
              Disease ID/Name: HetDR supports the inputed Disease name or
              theunique ID of disease.
            </li>
          )}
          {searchType === "t_DeepDTnet" && (
            <li>
              Target ID/Name: DeepDTnet supports the inputed Target name or the
              unique ID of target.
            </li>
          )}
          {searchType === "AOPEDF" && (
            <li>
              Target ID/Name: AOPEDF supports the inputed Target name or the
              unique ID of target.
            </li>
          )}
          {searchType === "CovKGE" && (
            <li>
              Target ID/Name: TarKGE supports the inputed Target name or the
              unique ID of target.
            </li>
          )}
          {searchType === "KGMTL" && (
            <li>
              Target ID/Name: KG-MTL supports the inputed Target name or the
              unique ID of target.
            </li>
          )}
          <li>
            Drugs_Top: Here you can choose to display the number of recommended
            drugs, and recommend the top drugs as the more potential drugs
            predicted by the model.
          </li>
          <li>
            Operation buttons: After inputtin the available target name, the
            user can select "predict" to make a prediction, and click "Reset" to
            re-input other Disease/Target. Users can quickly get the specific
            disease ID\Name through the "Search list".
          </li>
        </ol>
        <div></div>
      </div>
      <div></div>
    </div>
  );
}

export default inject("store")(observer(ServerPageModel));
