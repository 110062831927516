import { Timeline, Typography } from "antd";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
// import Tutorial_2_Disease_Centric_DeepDR_1 from "../../constants/img/Tutorial/2_Disease_Centric_DeepDR(1).png";
// import Tutorial_2_Disease_Centric_DeepDR_2 from "../../constants/img/Tutorial/2_Disease_Centric_DeepDR(2).png";
// import Tutorial_2_Target_Centric_DeepDR_1 from "../../constants/img/Tutorial/2_Target_Centric_DeepDR(1).png";
// import Tutorial_2_Target_Centric_DeepDR_2 from "../../constants/img/Tutorial/2_Target_Centric_DeepDR(2).png";
// import Tutorial_2_DisKGE from "../../constants/img/Tutorial/2_DisKGE.png";
// import Tutorial_3_1 from "../../constants/img/Tutorial/search_1.png";
// import Tutorial_result_3_1_2 from "../../constants/img/Tutorial/result_2.png";
// import Tutorial_Input_3_2 from "../../constants/img/Tutorial/Input_3_2.png";
// import Tutorial_result_3_2_1 from "../../constants/img/Tutorial/result_3_2_1.png";
// import Tutorial_result_3_2_2 from "../../constants/img/Tutorial/result_3_2_2.png";
// import Tutorial_result_3_1_1 from "../../constants/img/Tutorial/result_3_1_1.png";

import React from "react";
import "./tutorial.css";
import { Anchor } from "antd";
import KG_MTL from "../../constants/img/KG-MTL.png";
import path_map_1 from "../../constants/img/path_map_1.png";
import path_map_2 from "../../constants/img/path_map_2.png";
const { Link } = Anchor;

const { Title, Paragraph } = Typography;
function Tutorial(store) {
  useEffect(() => {
    let current = [];
    for (let i = 0; i < 100; i++) {
      current.push(70 + Math.floor(Math.random() * 10) + 1);
    }
    console.log(current);
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  useEffect(() => {
    setTargetOffset(window.innerHeight / 2);
  }, []);
  const [targetOffset, setTargetOffset] = useState(undefined);

  const handleClick = (e, link) => {
    e.preventDefault();
    console.log(link);
  };

  return (
    <div className="tutorial-body">
      <div className="tutorial-content">
        <div className="tutorial-Anchor-outer">
          <Anchor onClick={handleClick} targetOffset={targetOffset}>
            <div className="tutorial-Anchor-outer-position"></div>
            <Link href="#Basic-information" title="1. Basic information" />
            <Link href="#Model-construction" title="2. Model construction">
              <Link href="#DeepDR" title="2.1 DeepDR" />
              <Link href="#HetDR" title="2.2 HetDR" />
              <Link href="#DisKGE" title="2.3 DisKGE" />
              <Link href="#DeepDTnet" title="2.4 DeepDTnet" />
              <Link href="#AOPEDF" title="2.5 AOPEDF" />
              <Link href="#TarKGE" title="2.6 TarKGE" />
              <Link href="#KG-MTL" title="2.7 KG-MTL" />
            </Link>
            <Link href="#Predictive-analysis" title="3. Predictive analysis">
              <Link
                href="#Disease-Centric-DeepDR"
                title="3.1 Disease-Centric DeepDR"
              >
                <Link href="#3-1-Input" title="3.1.1 Input" />
                <Link href="#3-1-Output" title="3.1.2 Output" />
              </Link>
              <Link
                href="#Target-Centric-DeepDR"
                title="3.2 Target-Centric DeepDR"
              >
                <Link href="#3-2-Input" title="3.2.1 Input" />
                <Link href="#3-2-Output" title="3.2.2 Output" />
              </Link>
            </Link>

            <Link href="#Resources-Introduce" title="4. Resources Introduce">
              <Link href="#DeepDR-HeTDR" title="4.1 DeepDR\HeTDR" />
              <Link href="#DeepDTnet-AOPEDF" title="4.2 DeepDTnet\AOPEDF" />
              <Link href="#DisKGE-TarKGE" title="4.3 DisKGE\TarKGE" />
              <Link href="#KG-MTL-RI" title="4.4 KG-MTL" />
            </Link>
          </Anchor>
        </div>
        <div className="tutorial-main">
          <Timeline className="tutorial-Timeline">
            <Timeline.Item>
              <Typography className="tutorial-Typography">
                <Title level={2} id="Basic-information">
                  Basic information
                </Title>
                <Paragraph>
                  <b>DeepDR</b> aims to recommend potential drugs for a given target
                  or dise ase, based on six deep learning models developed by our
                  group, including <b>DeepDR</b>, DeepDTnet, AOPEDF, HetDR, Cov-KGE,
                  and KG-MTL. <b>DeepDR</b> provides two major functions:
                  “Disease-Centric <b>DeepDR</b>” and “Target-Centric <b>DeepDR</b>
                  ”. protein The "Disease-Centric <b>DeepDR</b>" service provides
                  users with three model options: a model based on heterogeneous
                  networks, a model that integrates heterogeneous networks and text
                  mining, and a model grounded in knowledge graphs. Users can choose
                  an appropriate model and input a disease name or ID to receive a
                  ranked list of potential drug recommendations. The "Target-Centric{" "}
                  <b>DeepDR</b>" service provides users with four model options: two
                  based on heterogeneous networks, one grounded in knowledge graphs,
                  and a synergistic model combining knowledge graphs with molecular
                  graphs. Users can choose an appropriate model and input the name
                  or ID of a target protein to receive a ranked list of potential
                  drug recommendations. In addition, based on the knowledge graph
                  drug recommendation model for diseases or proteins, the web server
                  provides a visualized relationship path network graph of the
                  predicted drug, which offers the path-level explanation to users.
                </Paragraph>
              </Typography>
            </Timeline.Item>
            <Timeline.Item>
              <Typography className="tutorial-Typography">
                <Title level={2} id="Model-construction">
                  Model construction
                </Title>
                <Paragraph>
                  The "Disease-Centric <b>DeepDR</b> " includes three deep learning
                  models:
                </Paragraph>
                <Title level={3} id="DeepDR">
                  {" "}
                  2.1 DeepDR
                  <sup>
                    <a href="https://doi.org/10.1093/bioinformatics/btz418">1</a>
                  </sup>
                </Title>
                <Paragraph>
                  This model is a network-based deep-learning method for drug
                  repositioning by integrating 10 networks: one drug-disease, one
                  drug-side-effect, one drug-target, and seven drug-drug
                  networks. Specifically, deepDR learns high-level features of drugs
                  from the heterogeneous networks by a multi-modal deep
                  autoencoder. Then the learned low-dimensional representation of
                  drugs together with clinically reported drug-disease pairs are
                  encoded and decoded collectively via a variational autoencoder to
                  infer candidates for approved drugs for which they were not
                  originally approved.
                  <a href="https://github.com/ChengF-Lab/deepDR">
                    (https://github.com/ChengF-Lab/deepDR)
                  </a>
                </Paragraph>
                <div className="tutrial-img-outline">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_Disease_Centric_DeepDR(1).png"
                    }
                    className="tutrial-img"
                  />
                </div>
                <Title level={3} id="HetDR">
                  2.2 HeTDR
                  <sup>
                    <a href="https://doi.org/10.1016/j.patter.2021.100307">3</a>
                  </sup>
                </Title>
                <Paragraph>
                  HeTDR, a drug repositioning method, leverages heterogeneous
                  networks and text mining to integrate drug features from various
                  networks with disease features from biomedical corpora, thereby
                  predicting drug-disease interactions. First, HeTDR employs with
                  global information and then utilizes the SAE to obtain the
                  high-quality feature representation of the drug. Similarity
                  Network Fthe Stacked Autoencoder (SAE) to derive high-quality drug
                  feature representations. HeTDR also harnesses the BioBERT model
                  for extracting disease feature information from biomedical texts.
                  Sically, it adapts BioBERT's pre-trained parameters and applies
                  relation extraction tasks for refinement. Posing, HeTDR re
                  representations and compilesdisease representations using
                  frequently occurring sub-words. Ultimately, HeTDR amalgamates the
                  drug-disease associatiith drug and dre data, aiming to
                  deduceinteractionsdrug-disease associations.
                  <a href="https://github.com/stjin-XMU/HeTDR">
                    (https://github.com/stjin-XMU/HeTDR)
                  </a>
                  <div className="tutrial-img-outline">
                    <img
                      src={
                        "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_Disease_Centric_DeepDR(2).png"
                      }
                      className="tutrial-img"
                    />
                  </div>
                </Paragraph>
                <Title level={3} id="DisKGE">
                  2.3 DisKGE
                  <sup>
                    <a href="https://10.1021/acs.jproteome.0c00316">5</a>
                  </sup>
                </Title>
                <Paragraph>
                  DisKGE is a variant from CovKGE
                  <sup>
                    <a href="https://10.1021/acs.jproteome.0c00316">5</a>
                  </sup>
                  , which uses a comprehensive biological knowledge graph called
                  DRKG relating genes, compounds, diseases, biological processes,
                  side effects, and symptoms. DRKG contains 5.9 million edges across
                  107 types of relationships connecting drugs, diseases,
                  proteins/genes, pathways, and expression from a large scientific
                  corpus of 24 million PubMed publications and six existing
                  databases including DrugBank, Hetionet, GNBR, String, IntAct and
                  DGIdb. DisKGE applies a deep learning methodology, RotatE, for in
                  silico prediction of small molecule drugs for known diseases.
                  Specifically, DisKGE leverages RotatE, an unsupervised knowledge
                  graph embedding method, to learn low-dimensional but informative
                  vector representations for all entities (e.g., drugs, diseases) in
                  DRKG. Subsequently, DisKGE predicts candidate drugs with potential
                  therapeutic effects for a certain disease by calculating the
                  distance between drugs and diseases in KG. After obtaining the
                  list of drug candidates, DisKGE provides the top-20 predicted
                  drugs for every disease in KG.
                  <a href="https://github.com/ChengF-Lab/CoV-KGE">
                    （https://github.com/ChengF-Lab/CoV-KGE）
                  </a>
                  <div className="tutrial-img-outline">
                    <img
                      src={
                        "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_DisKGE.png"
                      }
                      className="tutrial-img"
                    />
                  </div>
                </Paragraph>
                <Paragraph>
                  The "Target-Centric <b>DeepDR</b> " include three deep learning
                  models:
                </Paragraph>
                <Title level={3} id="DeepDTnet">
                  2.4 DeepDTnet
                  <sup>
                    <a href="https://doi.org/10.1039/C9SC04336E">2</a>
                  </sup>
                </Title>
                <Paragraph>
                  DeepDTnet is a deep learning methodology for new target
                  identification and drug repositioning in a heterogeneous
                  drug-gene-disease network embedding 15 types of chemical, genomic,
                  phenotypic, and cellular network profiles. DeepDTnet used a Deep
                  Neural network for Graph Representations (DNGR) algorithm to learn
                  low-dimensional but informative vectors representations for both
                  drugs and targets by a unique integration of large-scale chemical,
                  genomic, and phenotypic profiles, outperforming previously
                  published approaches. Due to the lack of experimental reports on
                  negative samples (i.e., non-interactions between drugs and
                  targets) in publicly available databases, DeepDTnet utilizes the
                  Positive-Unlabeled (PU) matrix completion algorithm for low-rank
                  matrix completion. This approach is able to infer whether two
                  drugs share a target without negative samples as input.
                  <a href="https://github.com/ChengF-Lab/deepDTnet/">
                    (https://github.com/ChengF-Lab/deepDTnet)
                  </a>
                </Paragraph>{" "}
                <div className="tutrial-img-outline">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_Target_Centric_DeepDR(1).png"
                    }
                    className="tutrial-img"
                  />
                </div>
                <Title level={3} id="AOPEDF">
                  2.5 AOPEDF
                  <sup>
                    <a href="https://doi.org/10.1093/bioinformatics/btaa010">4</a>
                  </sup>
                </Title>
                <Paragraph>
                  {" "}
                  The model is a network-based computational framework, which uses
                  an arbitrary-order proximity embedded deep forest approach for the
                  prediction of drug-target interactions. AOPEDF learns a
                  low-dimensional vector representation of features that preserve
                  arbitrary-order proximity from a highly integrated, heterogeneous
                  biological network connecting drugs, targets (proteins), and
                  diseases. In total, the model constructs a heterogeneous network
                  by uniquely integrating 15 networks covering chemical, genomic,
                  phenotypic, and network profiles among drugs, proteins/targets,
                  and diseases. Then, AOPEDF builds a cascade deep forest classifier
                  to infer new drug-target interactions.
                  <a href="https://github.com/ChengF-Lab/AOPEDF">
                    (https://github.com/ChengF-Lab/AOPEDF)
                  </a>
                </Paragraph>
                <div className="tutrial-img-outline">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/2_Target_Centric_DeepDR(2).png"
                    }
                    className="tutrial-img"
                  />
                </div>
                <Title level={3} id="TarKGE">
                  2.6 TarKGE
                  <sup>
                    <a href="https://10.1021/acs.jproteome.0c00316">5</a>
                  </sup>
                </Title>
                <Paragraph>
                The model is similar to DisKGE, which is also a variant of CovKGE <sup>
                    <a href="https://10.1021/acs.jproteome.0c00316">5</a>
                  </sup>. The difference is that DisKGE predicts candidate drugs with the potential to target a specific protein by calculating the distance between drugs and s in KG. After obtaining the list of drug candidates, DisKGE provides the top-20 predicted drugs for every target in KG. 
                  <a href="https://github.com/ChengF-Lab/CoV-KGE">
                    （https://github.com/ChengF-Lab/CoV-KGE）
                  </a>
                </Paragraph>
                <Title level={3} id="KG-MTL">
                  2.7 KG-MTL
                  <sup>
                    <a href="https://doi.org/10.1109/TKDE.2022.3188154">6</a>
                  </sup>
                </Title>
                <Paragraph>
                  The model is a large-scale Knowledge Graph enhanced Multi-Task
                  Learning model, called KG-MTL, which extracts the features from
                  both knowledge graph and molecular structure graph in a
                  synergistic way. The model is the first work to apply a
                  large-scale knowledge graph on a multi-task learning model, and
                  consists of three major modules. Specifically, <i>(i)</i> DTI
                  module is used to extract the features of drugs and related
                  entities from large-scale KG. <i>(ii)</i> CPI module is adopted to
                  learn two representations of the molecular graph and protein
                  sequences.
                  <i>(iii)</i> Shared Unit is designed to share task-independent
                  drug features between the previous two modules, by combining the
                  molecular representation of compound and corresponding drug entity
                  embedding from KG.
                  <a href="https://github.com/xzenglab/KG-MTL">
                    (https://github.com/xzenglab/KG-MTL)
                  </a>
                </Paragraph>
                <div className="tutrial-img-outline">
                  <img src={KG_MTL} className="tutrial-img" />
                </div>
              </Typography>
            </Timeline.Item>
            <Timeline.Item>
              <Typography className="tutorial-Typography">
                <Title level={2} id="Predictive-analysis">
                  3. Predictive analysis
                </Title>
                <Title level={3} id="Disease-Centric-DeepDR">
                  {" "}
                  3.1 Disease-Centric DeepDR
                </Title>
                <Title level={4} id="3-1-Input">
                  {" "}
                  3.1.1 Input
                </Title>

                <div className="tutrial-img-outline">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/search_1.png"
                    }
                    className="tutrial-img"
                  />
                </div>
                <Paragraph>
                  1. Model Options: Users can select a specific model for
                  prediction.
                  <br />
                  2. Disease ID\Name: DeepDR supports the input disease MeSH ID or
                  disease Name. "Disease List" provides all predictable diseases
                  Name and ID to facilitate access to predictable disease
                  information. And users can click on specific diseases to directly
                  predict. "Example" provides a sample example of automatic filling
                  (e.g., C0342731/Deficiency of mevalonate kinase/20), users can
                  directly click to view the predictive case.
                  <br />
                  3. Drugs_Top: Here users can choose to display the number of
                  recommended drugs, and the web server will display the ranking of
                  the corresponding number of drugs according to the predicted
                  correlation.
                  <br />
                  4. Operation buttons: After input the available disease ID\Name
                  and Drugs Top, the user can select "Predict" to make a prediction,
                  and click "Reset" to re-input other diseases.
                </Paragraph>

                <Title level={4} id="3-1-Output">
                  {" "}
                  3.1.2 Output
                </Title>
                <div className="tutrial-img-outline">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/result_3_1_1.png"
                    }
                    className="tutrial-img"
                  />
                </div>
                <div className="tutrial-img-outline">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/result_2.png"
                    }
                    className="tutrial-img"
                  />
                </div>
                <Paragraph>
                  1. Predictive result display page: User can click the button of
                  details to get information of drug.
                  <br />
                  2. Drug-related details.
                  <br />
                  3. Top-20 drugs that are similar to predicted drug obtained
                  through related information such as chemical, genomic, phenotypic,
                  and cellular network profiles. (Here DisKGE model provides a
                  visualized relationship path network graph, and selects with the
                  mouse to view the information of nodes and edges. )
                </Paragraph>
                <div className="tutrial-img-outline">
                  <img src={path_map_1} className="tutrial-img" />
                </div>
              </Typography>
            </Timeline.Item>
            <Timeline.Item>
              <Typography className="tutorial-Typography">
                <Title level={3} id="Target-Centric-DeepDR">
                  3.2 Target-Centric DeepDR
                </Title>
                <Title level={4} id="3-2-Input">
                  {" "}
                  3.2.1 Input
                </Title>
                <div className="tutrial-img-outline">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/Input_3_2.png"
                    }
                    className="tutrial-img"
                  />
                </div>

                <Paragraph>
                  1. Model Options: Users can select a specific model for
                  prediction.
                  <br />
                  2. Target ID\Name: <b>DeepDR</b> supports the input target unique
                  ID\Name. "Target List" provides all predictable targets Name and
                  ID to facilitate access to predictable target information.
                  "Example" provides a sample example of automatic filling (e.g.,
                  9971/NR1H4/20), users can directly click to view the predictive
                  case.
                  <br />
                  3. Drugs Top: Here users can choose to display the number of
                  recommended drugs, and the web server will display the ranking of
                  the corresponding number of drugs according to the predicted
                  correlation.
                  <br />
                  4. Operation buttons: After input the available target ID\Name and
                  Drugs Top, the user can select "Predict" to make a prediction, and
                  click "Reset" to re-input other targets.
                </Paragraph>

                <Title level={3} id="3-2-Output">
                  3.2.2 output
                </Title>
                <div className="tutrial-img-outline">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/result_3_2_1.png"
                    }
                    className="tutrial-img"
                  />
                </div>
                <div className="tutrial-img-outline">
                  <img
                    src={
                      "https://webserverdeepdr.oss-cn-beijing.aliyuncs.com/result_3_2_2.png"
                    }
                    className="tutrial-img"
                  />
                </div>
                <Paragraph>
                  1. Predictive result display page: User can click the button of
                  details to get information of drug.
                  <br />
                  2. Drug-related details.
                  <br />
                  3. Top-20 drugs that are similar to predicted drug obtained
                  through related information such as chemical, genomic, phenotypic,
                  and cellular network profiles. (Here TarKGE model provides a
                  visualized relationship path network graph, and selects with the
                  mouse to view the information of nodes and edges. )
                </Paragraph>
                <div className="tutrial-img-outline">
                  <img src={path_map_2} className="tutrial-img" />
                </div>
              </Typography>
            </Timeline.Item>

            <Timeline.Item>
              <Typography className="tutorial-Typography">
                <Title level={2} id="Resources-Introduce">
                  4. Resources Introduce
                </Title>
                <Title level={3} id="DeepDR-HeTDR">
                  {" "}
                  4.1 DeepDR\HeTDR
                </Title>
                <Paragraph>
                  <code>disease_dict</code>: list of disease unique identifier and
                  disease names <br />
                  <code>drugDisease.txt</code>: Drug-Disease association matrix{" "}
                  <br />
                  <code>drugProtein.txt</code>: Drug-Protein association matrix{" "}
                  <br />
                  <code>drug_dict</code>: list of drug unique identifier and drug
                  names <br />
                  <code>drugdrug.txt</code>: Drug-Drug interaction matrix <br />
                  <code>drugsideEffect.txt</code>: Drug-SideEffect association
                  matrix <br />
                  <code>drugsimBPnet.txt</code>: Drug biological processes
                  similarity matrix <br />
                  <code>drugsimCCnet.txt</code>: Drug cellular component similarity
                  matrix <br />
                  <code>drugsimChemicalnet.txt</code>: Drug chemical similarity
                  matrix <br />
                  <code>drugsimMFnet.txt</code>: Drug molecular function similarity
                  matrix <br />
                  <code>drugsimMetanet.txt</code>: Drug Side-effect similarity
                  matrix <br />
                  <code>drugsimTherapeuticnet.txt</code>: Drug therapeutic
                  similarity matrix <br />
                  <code>drugsimWmnet.txt</code>: Drug sequence similarity matrix{" "}
                  <br />
                  <code>protein_dict</code>: list of protein unique identifier and
                  protein names <br />
                  <code>sideEffect_dict.txt</code>: list of side effect unique
                  identifier and side effect names <br />
                </Paragraph>

                <Title level={3} id="DeepDTnet-AOPEDF">
                  {" "}
                  4.2 DeepDTnet\AOPEDF
                </Title>
                <Paragraph>
                  <code>disease_dict.txt</code>: list of disease unique identifier
                  and disease names <br />
                  <code>drugDisease.txt</code>: Drug-Disease association matrix{" "}
                  <br />
                  <code>drugProtein.txt</code>: Drug-Protein association matrix{" "}
                  <br />
                  <code>drug_dict.txt</code>: list of drug unique identifier and
                  drug names <br />
                  <code>drugdrug.txt</code>: Drug-Drug interaction matrix <br />
                  <code>drugsideEffect.txt</code>: Drug-SideEffect association
                  matrix <br />
                  <code>drugsim1network.txt</code>: Drug chemical similarity matrix{" "}
                  <br />
                  <code>drugsim2network.txt</code>: Drug therapeutic similarity
                  matrix <br />
                  <code>drugsim3network.txt</code>: Drug sequence similarity matrix{" "}
                  <br />
                  <code>drugsim4network.txt</code>: Drug biological processes
                  similarity matrix <br />
                  <code>drugsim5network.txt</code>: Drug cellular component
                  similarity matrix <br />
                  <code>drugsim6network.txt</code>: Drug molecular function
                  similarity matrix <br />
                  <code>proteinDisease.txt</code>: Protein-Disease association
                  matrix <br />
                  <code>protein_dict.txt</code>: list of protein unique identifier
                  and protein names <br />
                  <code>proteinprotein.txt</code>: Protein-Protein interaction
                  matrix <br />
                  <code>proteinsim1network.txt</code>: Protein sequence similarity
                  matrix <br />
                  <code>proteinsim2network.txt</code>: Protein biological processes
                  similarity matrix <br />
                  <code>proteinsim3network.txt</code>: Protein cellular component
                  similarity matrix <br />
                  <code>proteinsim4network.txt</code>: Protein molecular function
                  similarity matrix <br />
                  <code>sideEffect_dict.txt</code>: list of side effect unique
                  identifier and side effect names <br />
                </Paragraph>
                <Title level={3} id="DisKGE-TarKGE">
                  {" "}
                  4.3 DisKGE\TarKGE
                </Title>
                <Paragraph>
                  <code>drkg.tsv</code>: a tsv file containing the original drkg in
                  the format of (h, r, t) triplets.
                  <br />
                  <code>entity2src.tsv</code>: a file mapping entities in drkg to
                  their original sources.
                  <br />
                  <code>relation_glossary.tsv</code>: a file containing rge glossary
                  of the relations in DRKG, and other associated information with
                  sources (if available).
                </Paragraph>
              </Typography>
            </Timeline.Item>
            <Timeline.Item></Timeline.Item>
          </Timeline>
        </div>
      </div>
    </div>
  );
}

export default inject("store")(observer(Tutorial));
